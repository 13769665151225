
import LmCard from "@/components/LMCard.vue";
import CenteredContent from "@/components/CenteredContent.vue";
import { GlobalConfig, StatusType, VForm } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import Config from "@/mixins/config";
import { focus } from "@/directives/focus";
import ForgotPasswordInput from "@/components/ForgotPasswordInput.vue";
import FormContainer from "@/components/registration/FormContainer.vue";

@Component({
  components: { LmCard, FormContainer, CenteredContent, ForgotPasswordInput },
  directives: { focus }
})
export default class ForgotPassword extends Mixins(Config) {
  name = "ForgotPassword";

  title = this.$t("forgot-password.title");

  validForm = true;

  inputValid = false;

  public username = "";

  success = false;

  backendError = "";

  globalConfig: GlobalConfig = {
    inputFields: {
      emailAddress: {
        enabled: true,
        plainInput: false
      },
      mobileNumber: {
        enabled: false,
        plainInput: false
      }
    }
  };

  emailAddressRegex = /^(?!@)[^@]+@[^@]+(?!@)$/;
  mobileNumberRegex = /^\+\d{1,3}(\(0\)\d{3}|\d{3,4}|\(\d{3}\))\d{7}$/;

  rules: any[] = [
    (value: string) => !!value || this.$t("forgot-password.input.error.missing")
  ];

  async created() {
    const regFlow = await this.getRegistrationFlow();
    this.$store.commit("registration/setRegistrationFlow", regFlow);

    this.globalConfig = this.getGlobalConfig();

    const emailAddressEnabled: boolean =
      this.globalConfig.inputFields.emailAddress.enabled;
    const mobileNumberEnabled: boolean =
      this.globalConfig.inputFields.mobileNumber.enabled;

    this.rules.push(
      (value: string) =>
        (emailAddressEnabled && this.emailAddressRegex.test(value)) ||
        (mobileNumberEnabled && this.mobileNumberRegex.test(value)) ||
        this.$t("forgot-password.input.error.wrong")
    );
  }

  get emailAddressConfig() {
    return this.globalConfig.inputFields.emailAddress;
  }

  get loading() {
    return this.$store.getters["app/status"] === StatusType.sendingResetPwdReq;
  }

  async validInput() {
    (this.$refs.form as VForm).validate();

    await this.$nextTick();
    this.forgotPassword();
  }

  handleValidChange(newValue: boolean) {
    this.inputValid = newValue;
  }

  forgotPassword() {
    if (this.validForm && this.inputValid) {
      this.backendError = "";
      if (this.emailAddressRegex.test(this.username)) {
        this.$store.commit("forgotPassword/setEmailAddress", this.username);
      } else if (this.mobileNumberRegex.test(this.username)) {
        this.$store.commit("forgotPassword/setMobileNumber", this.username);
      }

      this.$store
        .dispatch("forgotPassword/sendResetPwdReq")
        .then(() => (this.success = true))
        .catch((error) => {
          this.backendError = error;
          (this.$refs.form as VForm).validate();
          this.$store.commit("app/setStatus", null);
        });
    }
  }
}
