
import CenteredContent from "@/components/CenteredContent.vue";
import LmCard from "@/components/LMCard.vue";
import { focus } from "@/directives/focus";
import Config from "@/mixins/config";
import { HttpService } from "@/services/HttpService";
import { StatusType, VForm } from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import VueI18n from "vue-i18n";

@Component({ components: { LmCard, CenteredContent }, directives: { focus } })
export default class ConfirmOptIn extends Mixins(Config) {
  name = "ConfirmOptIn";
  title = this.$t("optin.title");
  public optinID: string | null = null;
  public optinIDRules = [] as Array<
    (value: string) => true | string | VueI18n.TranslateResult
  >;
  get loading() {
    return this.$store.getters["app/status"] === StatusType.checkingOptIn;
  }
  mounted() {
    this.optinID = this.$route.params.optinID;
    this.validateOptin();
  }
  validateOptin() {
    if (this.optinID) {
      this.$store.commit("app/setStatus", StatusType.checkingOptIn);
      HttpService.post("/persons/optins/" + this.optinID)
        .then(() => {
          window.location.replace(
            (process.env.VUE_APP_PRIVATE_APP_URL || "#") +
              "?origin=optInConfirmation"
          );
        })
        .catch((error) => {
          if (error.response) {
            this.optinIDRules.push(() => this.$t("optin.error"));
            (this.$refs.form as VForm).validate();
            this.$store.commit("app/setStatus", null);
            this.$nextTick(() => this.optinIDRules.pop());
          } else {
            this.$store.commit("app/setStatus", StatusType.error);
          }
        });
    }
  }
}
