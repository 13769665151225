
import Vue from "vue";
import EmailAddressInput from "@/components/EmailAddressInput.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import TextField from "@/components/TextField.vue";
import { focus } from "@/directives/focus";
import {
  ComponentFieldConfig,
  RegistrationForm,
  RegistrationStepEnum,
  StatusType,
  VForm
} from "@/types";
import { Component, Mixins } from "vue-property-decorator";
import FormContainer from "@/components/registration/FormContainer.vue";
import Config from "@/mixins/config";
import { BasicInfoConfig } from "./BasicInfo.config";
import PasswordInputs from "@/components/PasswordInputs.vue";
import VueI18n from "vue-i18n";

@Component({
  components: {
    EmailAddressInput,
    TextField,
    FormContainer,
    PhoneNumberInput
  },
  directives: {
    focus
  }
})
export default class BasicInfo extends Mixins(Config) {
  name = "RegisterForm-BasicInfo";
  // default config
  formConfig: BasicInfoConfig = {
    component: "",
    firstName: { enabled: true },
    lastName: { enabled: true },
    emailAddress: { enabled: true },
    phoneNumber: { enabled: false },
    passwords: { enabled: true }
  };

  form: RegistrationForm = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    phoneNumber: "",
    ...this.$store.getters["registration/form"]
  };

  formfirstName = "";

  // passwordInfos component
  PasswordInputs: any = null;
  BasicInfoFunctions: any = null;

  // placeholder for the rules used to validate the form on submission
  rules = {
    firstNameRules: [
      (value: string) => !!value || this.$t("register.firstName.error")
    ],
    lastNameRules: [
      (value: string) => !!value || this.$t("register.lastName.error")
    ]
  };

  // status of the form
  valid = false;

  emailValid = false;
  handleEmailValidChange(newValue: boolean) {
    this.emailValid = newValue;
  }

  pwValid = false;
  handlePwValidChange(newValue: boolean) {
    this.pwValid = newValue;
  }

  submitValidationTrigger = false;
  backendError = "";

  get isSubmitable(): boolean {
    return !this.loading && this.valid && this.emailValid && this.pwValid;
  }

  get loading(): boolean {
    return (
      this.$store.getters["app/status"] === StatusType.checkingEmail ||
      this.$store.getters["app/status"] === StatusType.loadingOrgUnit
    );
  }
  /**
   * The following getters help to prevent nasty warnings of the parser
   * during Unit tests.
   */

  get firstNameConfig(): ComponentFieldConfig {
    return this.formConfig?.firstName;
  }

  get lastNameConfig(): ComponentFieldConfig {
    return this.formConfig?.lastName;
  }

  get emailAddressConfig(): ComponentFieldConfig {
    return this.formConfig?.emailAddress;
  }

  get phoneNumberConfig(): ComponentFieldConfig {
    return this.formConfig?.phoneNumber;
  }

  get passwordsConfig(): ComponentFieldConfig {
    return this.formConfig?.passwords;
  }

  // Load password input component
  async created() {
    this.PasswordInputs = await this.getComponent("PasswordInputs");
    this.BasicInfoFunctions = await this.getFunctions("BasicInfoFunctions");
    this.formConfig = this.getFormConfig(RegistrationStepEnum.BASIC_INFO);
    const extensionUpdater = await this.getFunctions("ExtensionUpdater");
    this.$store.commit("registration/setExtensionUpdater", extensionUpdater);
  }

  async validateForm() {
    this.validatePlainComponents();
    (this.$refs.form as VForm).validate();
    if (this.isSubmitable) {
      await Vue.nextTick();
      await this.BasicInfoFunctions.validEmailAdress(this);
    }
  }

  async validatePlainComponents() {
    this.submitValidationTrigger = !this.submitValidationTrigger;
  }

  error(type: string) {
    this.backendError = type;
    (this.$refs.form as VForm).validate();
    this.$store.commit("app/setStatus", null);
  }

  async nextStep(): Promise<void> {
    await Vue.nextTick();
    this.$store.commit("registration/setForm", this.form);
    this.$store.commit("app/setStatus", null);
    await this.$store.dispatch("registration/nextStep");
  }
}
