
import BasicInfo from "@/components/registration/steps/BasicInfo.vue";
import PolicyDialog from "@/components/registration/steps/PolicyDialog.vue";
import LmCard from "@/components/LMCard.vue";
import Config from "@/mixins/config";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { StatusType, RegistrationStepEnum, RegistrationFlow } from "@/types";
import ChooseOrgUnit from "@/components/registration/steps/ChooseOrgUnit.vue";
import OtherInfo from "@/components/registration/steps/OtherInfo.vue";

@Component({
  components: {
    LmCard,
    BasicInfo,
    ChooseOrgUnit,
    OtherInfo,
    PolicyDialog
  }
})
export default class RegisterForm extends Mixins(Config) {
  name = "RegisterForm";

  title = this.$t("register.title");
  cardTitle = this.$t("register.card-title");

  async created() {
    const config: RegistrationFlow = await this.getConfig("registrationFlow");
    const demoAccountConfig =
      config.globalConfig.demoAccounts[this.$route.path];
    if (demoAccountConfig) {
      this.cardTitle = this.$t(demoAccountConfig.title);
      this.$store.commit("registration/setDemoAccount", demoAccountConfig.id);
    }
  }

  get currentStepEnum(): RegistrationStepEnum {
    return this.$store.getters["registration/step"] as RegistrationStepEnum;
  }

  get loading(): boolean {
    return (
      this.$store.getters["app/status"] === StatusType.checkingEmail ||
      this.$store.getters["app/status"] === StatusType.loadingOrgUnit
    );
  }

  get registrationSuccessful(): boolean {
    return (
      this.$store.getters["app/status"] === StatusType.registrationSuccessful
    );
  }

  get currentComponent(): string {
    const config = this.getFormConfig(this.currentStepEnum);
    return (config || {}).component;
  }

  @Watch("registrationSuccessful")
  onRegistrationSuccessfulChanged(regSucc: boolean) {
    // if registration is successfull, leave the registration route and this
    // component and display confirmation page
    if (regSucc) {
      this.$router.push({ name: "registration-confirmation" });
    }
  }
}
