
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VueI18n from "vue-i18n";
import TextField from "@/components/TextField.vue";

@Component({
  components: {
    TextField
  }
})
export default class PhoneNumberInput extends Vue {
  @Prop({ default: "" })
  private value!: string;

  @Prop({ default: false })
  public enabled!: boolean;

  @Prop({ default: false })
  plainInput!: boolean;

  public phoneNumberRules: Array<
    (value: string) => true | string | VueI18n.TranslateResult
  > = [
    (value: string) => !!value || this.$t("phoneNumber.error.missing"),
    (value: string) => {
      const pattern = /^\+\d{1,3}(\(0\)\d{3}|\d{3,4}|\(\d{3}\))\d{7}$/;
      return pattern.test(value) || this.$t("phoneNumber.error.wrong");
    }
  ];

  public phoneNumber = this.value || "";

  validate(): void {
    //
  }

  /**
   * Sets an email rule with a localization code 'phoneNumber.error.{passed value}'
   */
  error(error: string): void {
    this.phoneNumberRules.push(() => this.$t("phoneNumber.error." + error));
  }

  push(rule: (value: string) => true | string | VueI18n.TranslateResult): void {
    this.phoneNumberRules.push(rule);
  }

  pop(): void {
    this.phoneNumberRules.pop();
  }

  // parent components can react to changes
  @Watch("phoneNumber")
  onPhoneNumberChanged() {
    this.$emit("input", this.phoneNumber);
  }
}
