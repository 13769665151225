/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpService } from "@/services/HttpService";
import { RegistrationStepEnum, StatusType } from "@/types";
import { ActionContext } from "vuex";
import { StateInterface } from "../state";
import { RegistrationState } from "./state";
import { LMNewPerson } from "./types";
import { AxiosRequestConfig } from "axios";

export interface RegistrationActions {
  register(
    context: ActionContext<RegistrationState, StateInterface>
  ): Promise<any>;
  previousStep(context: ActionContext<RegistrationState, StateInterface>): void;
  nextStep(context: ActionContext<RegistrationState, StateInterface>): void;
}

const SET_STATUS = "app/setStatus";

const getFlowState = (
  state: RegistrationState,
  step: RegistrationStepEnum | null
) => {
  return state.registrationFlow && step ? state.registrationFlow[step] : null;
};

export const actions: RegistrationActions = {
  async register({ commit, state }): Promise<true | any> {
    commit(SET_STATUS, StatusType.creatingAccount, {
      root: true
    });

    const newPerson: LMNewPerson = {
      givenName: state.form.firstName,
      surName: state.form.lastName,
      mailAddress: state.form.emailAddress,
      password: state.form.password,
      sourceSystem: "LM_REGISTRATION",
      otp: state.form.otp,
      gender: state.form.gender,
      preferredLanguage: state.form.preferredLanguage,
      orgUnits: state.form.orgUnit == null ? null : [state.form.orgUnit]
    };

    try {
      const createPersonCall = await HttpService.post(
        `/persons${
          state.demoAccount !== undefined
            ? `?demo-account=${state.demoAccount}`
            : ""
        }`,
        newPerson,
        {
          validateStatus: (status: any) => {
            return status === 201;
          }
        } as AxiosRequestConfig<any>
      );

      if (createPersonCall.status === 200 || createPersonCall.status === 201) {
        const extensionUpdates = state.extensionUpdater
          ? await state.extensionUpdater(state, createPersonCall)
          : true;

        if (extensionUpdates === true) {
          commit("setForm", null);
          commit("setStep", 1);
        }

        return extensionUpdates;
      }
    } catch (reason: unknown) {
      return reason;
    }
  },
  /**
   * Go to previous step. Skip previous step if the previous step
   * has a precondition that is not fulfilled
   */
  async previousStep({ commit, state, dispatch }): Promise<void> {
    // no flow set? skip nextStep
    if (!state.registrationFlow) {
      return;
    } else {
      const currentFlowStep = getFlowState(state, state.step);
      const previousStep = getFlowState(
        state,
        currentFlowStep ? currentFlowStep.previousStep : null
      );
      // if there is a precondidition to this step, evaluate it first
      const preConditionFunctionForPreviousStep = previousStep?.preCondition;

      // if there is a pre-condition function, evaluate it
      const preCondition = preConditionFunctionForPreviousStep
        ? preConditionFunctionForPreviousStep(state)
        : true;

      // commit to previous step
      commit(
        "setStep",
        state.step === null ? null : currentFlowStep?.previousStep
      );

      // is there a previous step?
      if (currentFlowStep?.previousStep) {
        // precondition not met? skip this step and proceed to next
        if (!preCondition) {
          await dispatch("previousStep");
        }
      } /*
      else {  // first step - anything to do here? }
        */
    }
  },
  /**
   * Go to next step. Skip next step if the next step
   * has a precondition that is not fulfilled.
   * If there is no next step, the registration is completed.
   * If the current step of the store is null, the first step
   * of the flow is taken.
   */
  async nextStep({ commit, state, dispatch }): Promise<void> {
    // no flow set? skip nextStep
    if (!state.registrationFlow) {
      return;
    } else {
      // when state step null, start at first step
      if (state.step === null) {
        // get first step (no previous step configured)
        let firstStep = null;
        (Object.keys(state.registrationFlow) as RegistrationStepEnum[]).forEach(
          (step) => {
            if (
              state.registrationFlow &&
              state.registrationFlow[step]?.previousStep === null
            ) {
              firstStep = step;
            }
          }
        );
        state.step = firstStep ?? RegistrationStepEnum.BASIC_INFO;
      }
      const currentFlowStep = getFlowState(state, state.step);
      const nextStep = getFlowState(
        state,
        currentFlowStep ? currentFlowStep.nextStep : null
      );

      // if there is a precondidition to this step, evaluate it first
      const preConditionFunctionForNextStep = nextStep?.preCondition;

      const preCondition = preConditionFunctionForNextStep
        ? preConditionFunctionForNextStep(state)
        : true;
      // commit to next step
      commit("setStep", state.step === null ? null : currentFlowStep?.nextStep);

      // is there a next step?
      if (currentFlowStep?.nextStep) {
        // precondition for next step not met? skip this step and proceed to next
        if (!preCondition) {
          await dispatch("nextStep");
        }
      }
    }
  }
};
